import stop from "../assets/stop.png";
import tombdump from "../assets/tombdump.png";
import styled from "styled-components";
import TokenAddressButton from "./TokenAddressButton";
import StyledHeading from "./StyledHeading.js";
import StyledPara from "./StyledPara";
import Button from "./Button";
const HeroSection = () => {
  return (
    <StyledPaper>
      <div>
        <img src={stop} alt="stop" className="hero-section-stop" />
      </div>
      <div>
        <img src={tombdump} alt="tomb dump" className="hero-section-tombdump" />
      </div>
      <StyledH2>
        Instead, learn to take profits the right way with Tomb Finance{" "}
      </StyledH2>
       <TokenAddressButton />
      <StyledHeading>Wall of Shame</StyledHeading>
      <StyledPara>
        If your address appears on this page it is because you acted selfishly
        and not in good faith of the tomb protocol. We can do better!{" "}
      </StyledPara>
    </StyledPaper>
  );
};

const StyledPaper = styled.div`
  margin: 0;
`;
const StyledH2 = styled.h2`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 40px;
  color: #fff2d1;
  text-shadow : 0 0 10px #888;
  padding: 0 1rem;
  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
  @media (max-width: 400px) {
    font-size: 1.6rem;
  }
`;
export default HeroSection;
