import * as React from "react";
import styled from "styled-components";
const Link = (props) => {

  const url = `https://ftmscan.com/${props.path}/${props.value}`;
  
  return (
    <StyledLink>
      <a href={url} target="_blank" rel="noreferrer">
        View on FTMScan
      </a>
    </StyledLink>
  );
};

const StyledLink = styled.div`
  background-color: #7955b7 !important;
  padding: 0.5rem;
  max-width: 150px !important;
  text-align: center;
  color: white;
  border-radius: 7px;
`;

export default Link;
