
import "./styles/App.css";
import DumpersTable2 from "./components/DumpersTable2";
import HeroSection from "./components/HeroSection";
import InfoGraphicsSection from "./components/InfoGraphicsSection";

function App() {
  return (
    <div className="App">
      <HeroSection />
      <section className="DumpersTableSection">
      <DumpersTable2 />
      </section>
      <InfoGraphicsSection/>
    </div>
  );
}  


export default App;
