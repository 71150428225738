import styled from "styled-components";
import React from "react";

const PrinterBringCard = () => {
  return (
    <StyledContainer>
      <p>
        THIS CAN ONLY CONTINUE IF WE WORK TOGETHER TO MINIMALIZE TOMB REWARD
        DUMPING
      </p>
      <p>
        STAKE, LEND, AUTOCOMPOUND, OR HOLD. DO NOT DUMP ALL TOMB TO FTM OR
        STABLE.
      </p>
      <p>
        IF YOU ABSOLUTELY NEED TO, SELL ONLY A % OF YOUR REWARDS TO MAI. THIS
        WAY YOU CAN UTLIZE THE TOMB-MAI POOL AND EARN MORE TSHARE!!
      </p>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(63, 59, 92, 0.65) 100%
  );
  backdrop-filter: blur(5px);
  border-radius: 29px !important;
  min-width: 100px;
  margin: auto;
  max-width: 450px;
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size : 1.40em;
  color: white !important;
  border: 2px solid #7955b7;
  padding:  1em 3em;
  text-shadow: 0 0 10px rgba(255, 242, 209, 0.66);
  @media (max-width: 950px) {
    font-size : 1em;
    padding:  0.5em 2em;
  }
`;

export default PrinterBringCard;
