import styled from "styled-components";

const StyledHeading = styled.h1`
  font-family: "Amarante", cursive;
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  color: #33285c;
  padding : 0 1em;
  margin :  0.5em 0;
  @media (max-width: 768px) {
    font-size: 3rem;
  }
  @media (max-width: 600px) {
    font-size: 2rem;
    margin :  0.5em 0;
  }
  @media (max-width: 400px) {
    font-size: 1.8rem;
    margin :  0.5em 0;
  }
`;

export default StyledHeading;
