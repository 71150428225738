import styled from "styled-components";

const StyledFooter = styled.h1`
  font-family: "Amarante", cursive;
  padding : 2em 0;
  margin :0;
  margin-top : 2em;
  font-style: normal;
  font-weight: 600;
  font-size: 1.30em;
  color: #fff2d1;
  background : rgba(0,0,0,0.3);
  text-shadow: 0 0 20px rgba(255, 242, 209, 0.66), 0 0 30px rgba(255, 242, 209, 0.66), 0 0 40px rgba(255, 242, 209, 0.66);
  @media (max-width: 768px) {
    font-size: 1em;
  }
  @media (max-width: 400px) {
    font-size: 0.8em;
  }
`;

export default StyledFooter;
