import React from "react";
import SupportPegImg from "../assets/SupportPegImg.png";
import StyledHeading from "./StyledHeading";
import ProfitImg from "../assets/ProfitImg.png";
import PrinterBringImg from "../assets/PrinterBringImg.png";
import StyledFooter from "./StyledFooter.js";
import PrinterBringCard from "./PrinterBringCard";

const InfoGraphicsSection = () => {
  return (
    <div>
      <section className="InfoGraphicsSection">
        <StyledHeading>How to support peg? </StyledHeading>
        <div className="InfoGraphics">
          <img
            src={SupportPegImg}
            alt="SupportPegImg"
            className="SupportPegImg"
          />
        </div>
        <StyledHeading>How to take profits? </StyledHeading>
        <div className="InfoGraphics">
          <img src={ProfitImg} alt="ProfitImg" className="ProfitImg" />
        </div>
      </section>

      <section className="footerSection">
        <StyledHeading>How to keep printer brrrring?</StyledHeading>
        <div className="InfoGraphics">
         <PrinterBringCard/>
        </div>
      </section>
      <StyledFooter>
            Made by <a href="https://0xlegion.com" target="_blank" style={{textDecoration : 'underline'}}> Neosage @ 0xLegion</a>, Tomb King Mattt, and Lord Shiba with <span style={{color : 'hotpink'}}>❤ </span> for Tomb Finance
      </StyledFooter>
    </div>
  );
};

export default InfoGraphicsSection;
