import * as React from "react";
import styled from "styled-components";
const Button = (props) => {
   
  return (
    <StyledLink>
      <a href={props.url} target="_blank" rel="noreferrer">
        {props.text}
      </a>
    </StyledLink>
  );
};

const StyledLink = styled.div`
  background-color: #7955b7 !important;
  padding: 0.5rem;
  max-width: 150px !important;
  text-align: center;
  color: white;
  border-radius: 7px;
  margin :  1rem auto;
`;

export default Button;