import * as React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Link from "./Link";

const columns = [
  { id: "Dumpers", label: "Dumpers", minWidth: 170 },
  { id: "Block", label: "Block", minWidth: 100 },
  {
    id: "TxnHash",
    label: "Txn Hash",
    minWidth: 170,
    align: "left",
  },
];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  margin: "1rem auto",
  width: "80%",
  border: "1px solid #981CD6",
  background: "rgba(255, 255, 255, 0.2)",
  borderRadius: "7px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export default function StickyHeadTable() {

  const [rows, setRows] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  // Fetching recent 100 dumpers using api 
  const fetchData = () => {
    fetch("https://server.stoptombdump.com/dumpers")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setRows(data);
        setOriginalData(data);
      }).catch((err)=>{
         console.error(err)
      });
  };

  useEffect(() => {
    fetchData();
  }, []);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = [];
    originalData.map((row) => {
      if (
        row.from.toLowerCase().includes(searchedVal.toString().toLowerCase())
      ) {
        filteredRows.push(row);
      }
      return true;
    });
    setRows(filteredRows);
  };

  return (
    <Paper sx={{ overflow: "hidden" }} className="dumperTable">
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            requestSearch(e.target.value);
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              console.log(searchTerm);
              console.log(rows);
              requestSearch(searchTerm);
            }
          }}
        />
      </Search>

      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ minWidth: "200px", margin: "auto", maxWidth: "80%" }}
        >
          <TableHead>
            <TableRow sx={{ background: "transparent" }}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: "transparent",
                    color: "white",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell
                      sx={{ color: "white" }}
                      align="left"
                      className="dumper"
                      onClick={() => {
                        navigator.clipboard.writeText(row.from);
                        alert("copied");
                      }}
                    >
                      {row.from}
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="left">
                      {row.blockNumber}
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="left">
                      <Link path="tx" value={row.txHash}></Link>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
