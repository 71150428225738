import React from "react";
import styled from "styled-components";

const TokenAddressButton = () => {
  return (
    <StyledButton
      onClick={() =>
        navigator.clipboard.writeText(
          "0x7c84e8946dd18c5afd8207dd42e598f5103ba7aa"
        )
      }
    >
      <a
        target="_blank"
        href="https://ftmscan.com/address/0x7c84e8946dd18c5afd8207dd42e598f5103ba7aa"
      >
        Stop Token Address: 0x7c84e8946dd18c5afd8207dd42e598f5103ba7aa
      </a>
    </StyledButton>
  );
};

const StyledButton = styled.div`
  padding: 0.8rem;
  font-size: 1.2rem;
  background: transparent;
  border: 2px solid #981cd6 !important;
  border-radius: 50px;
  text-shadow: 0 0 10px #888;
  cursor: pointer;
  margin: auto;
  word-wrap: break-word;
  max-width: 900px !important;
  transition: all 0.2s ease-in-out;
  margin-bottom : 3em !important;
  &:hover {
    background: #981cd6;
    color: white !important;
  }
  @media (max-width: 950px) {
    margin : 1rem;
    font-size : 1em
  }
`;

export default TokenAddressButton;
