import styled from "styled-components";

const StyledPara = styled.p`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  color: #fff2d1;
  max-width: 680px;
  padding: 0 2rem;
  margin: auto;
  text-shadow : 0 0 10px #888;
  @media (max-width: 600px) {
    font-size: 1em;
  }
`;

export default StyledPara;
